import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconBack.module.css';

const IconBack = props => {
    const { className, rootClassName, fill = "#ffffff" } = props;
    const classes = classNames(rootClassName || css.root, className);
    return (
        <svg className={classes} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h48v48H0z" fill="none"></path>
            <path d="M40 22H15.66l11.17-11.17L24 8 8 24l16 16 2.83-2.83L15.66 26H40v-4z" fill={fill} className="fill-000000"></path>
        </svg>
    );
};

IconBack.defaultProps = { className: null };

IconBack.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconBack;
